export const useAddressRulesStore = defineStore('addressRules', () => {
  const { utilities } = useApi()

  const rules = useSessionStorage('address-rules', {})
  const countries = useSessionStorage<{ label: string, value: string }[]>('country-list', [])

  const defaultFields = () => ({
    addressLine1: { validation: { required: true, maxLength: 30 } },
    addressLine2: { validation: { required: false, maxLength: 30 } },
    city: { validation: { required: true, maxLength: 30 } },
    country: { validation: { required: true } },
    email: { validation: { required: true } },
    firstName: { validation: { required: true, minLength: 2, maxLength: 30 } },
    lastName: { validation: { required: true, minLength: 2, maxLength: 30 } },
    phone: { validation: { required: true } },
    postalCode: { validation: { required: true } },
    province: { validation: { required: false } }
  })
  const defaultRules = () => ({ shipping: { ...defaultFields() }, billing: { ...defaultFields() } })

  /**
   * Loads the validation rules and countries from the API for the given country and locale
   * @param { string } countryCode - The country code
   * @param { string } locale - The locale
   * @returns { Promise<void> }
   */
  const get = (countryCode: string, locale: string) => {
    const localeKey = `${countryCode.toLocaleLowerCase()}-${locale}`

    return useAsyncData(() => Promise.all([
      !rules.value[localeKey] && utilities.$i18N(countryCode, {
        locale
      }).then((rulesResp) => {
        rules.value[localeKey] = defaultRules()
        for (const form_ of rulesResp) {
          for (const field of form_.fields) {
            rules.value[localeKey][form_.formId.replace('Form', '')][field.fieldId] = {
              ...field,
              validation: {
                ...rules.value[localeKey][form_.formId.replace('Form', '')][field.fieldId].validation,
                ...field.validation
              }
            }
          }
        }
      }),
      !countries.value.length && utilities.$countryList({ locale }).then((list) => {
        countries.value = list.map(({ name, code }) => ({ label: name, value: code }))
      })
    ])
    )
  }

  return {
    get,
    countries,
    rules
  }
})
