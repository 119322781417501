import type { LocaleCode } from '#types/locale'

type ValidatorName = 'required' | 'minLength' | 'maxLength' | 'pattern'

/**
 * @returns {{ load, validators, states, countries, getValidator }} API Rules validation methods and data
 */
export default () => {
  const addressRulesStore = useAddressRulesStore()
  const { validateMaxLength, validateMinLength, validateRegex, validateRequired } = useLocalizedValidators()
  const { $t } = useNuxtApp()

  // localeKey holds the current country and locale
  const localeKey = ref('')
  // formKey holds the form type
  const formKey = ref<'billing' | 'shipping' >('billing')

  /**
   * Loads the validation rules from the API for the given country and locale
   * @param countryCode - The country code
   * @param locale - The locale
   * @param approachType - The approach type: either B or S (billing / shipping)
   */
  const load = (countryCode: string, locale: LocaleCode, approachType: 'B' | 'S' = 'S') => {
    localeKey.value = `${countryCode.toLocaleLowerCase()}-${locale}`
    formKey.value = approachType === 'B' ? 'billing' : 'shipping'
    return addressRulesStore.get(countryCode, locale)
  }

  /**
   * Returns validators collection for the current locale and form type
   */
  const validators = computed(() => {
    if (!addressRulesStore.rules[localeKey.value]) return {}

    const rules = addressRulesStore.rules[localeKey.value][formKey.value]
    const validators_: Record<string, { [K in ValidatorName]?: [((...any) => Validator), ...any[]] }> = {}

    for (const field in rules) {
      const { required, pattern, minLength, maxLength } = (rules[field].validation || {})
      validators_[field] = {}
      if (required)
        validators_[field].required = [validateRequired, required, $t.validators.required]

      if (minLength) {
        validators_[field].minLength = [
          validateMinLength,
          minLength,
          replaceAll($t.validators.minLength, { min: minLength })
        ]
      }

      if (maxLength) {
        validators_[field].maxLength = [
          validateMaxLength,
          maxLength,
          replaceAll($t.validators.maxLength, { max: maxLength })
        ]
      }

      if (pattern) {
        let regex = new RegExp(pattern)
        const flags = pattern.match(/.*\/([gimyu]*)$/)?.[1]
        if (flags)
          regex = new RegExp(pattern.replace(new RegExp(`^/(.*?)/${flags}$`), '$1'), flags)

        validators_[field].pattern = [validateRegex, regex, $t.validation[field]]
      }
    }

    return validators_
  })

  const countries = computed(() => addressRulesStore.countries)

  const states = computed(() => (addressRulesStore.rules[localeKey.value]?.[formKey.value]?.province?.list || [])
    .map(({ name, code }) => ({ label: name, value: code })))

  /**
   * Returns validators collection for a given field name
   * @param { string } field The field name
   * @param { string } label Field label translation to be used in error messaging for required fields
   */
  const getValidator = (field: string, label: string, translations: { [K in ValidatorName]?: string } = {}) =>
    validators.value[field]
      ? Object.entries(validators.value[field]).map(([name, [validator, ...args]]) => {
        const _translations = {
          ...(name === 'required') && { required: replaceAll($t.validators.required, { field: label }) },
          ...translations
        }
        const message = _translations[name] || args.at(-1)
        return validator(...args.slice(0, -1), message)
      })
      : []

  return {
    load,
    validators,
    states,
    countries,
    getValidator
  }
}
